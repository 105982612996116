/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  CommoditySurcharge,
  CommoditySurchargeList,
  TierCalculation
} from "@/domain/entities/CommoditySurcharge";
import { Pagination } from "@/domain/entities/Pagination";
import { LocationPresenter } from "../presenters/LocationPresenter";
import { CityEntities } from "@/domain/entities/Location";
import {
  AddCommoditySurchargeApiRequest,
  EditCommoditySurchargeApiRequest
} from "@/data/payload/api/CommoditySurchargeApiRequest";
import { CommoditySurchargePresenter } from "../presenters/CommoditySurchargePresenter";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import {
  ApiRequestList,
  ModalMessageEntities
} from "@/domain/entities/MainApp";
export interface CommodityState {
  commoditySurchargeList: CommoditySurchargeList;
  commoditySurcharge: CommoditySurcharge;
  isLoading: boolean;
  isError: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: "commoditySurcharge" })
class CommoditySurchargeStore extends VuexModule implements CommodityState {
  public commoditySurchargeList = new CommoditySurchargeList(
    new Pagination(1, 10),
    []
  );
  public commoditySurcharge = new CommoditySurcharge(
    0,
    0,
    "",
    "",
    0,
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    []
  );
  public isLoading = false;
  public isLoadingAdd = false;
  public isError = false;
  public isErrorEdit = false;
  public openPopupError = false;
  public page = 1;
  public limit = 10;
  public search = "";
  public status = "";
  public errorCause = "";
  public openModal = false;
  public openSuccess = false;
  public typeCost = "";
  public addObjectOptionsData: Array<any> = [
    { name: "Publish Rate", value: "publish_rate" },
    {
      name: "Shipping Surcharge",
      value: "shipping_surcharge"
    },
    {
      name: "Origin District Rate",
      value: "origin_district_rate"
    },
    {
      name: "Destination District Rate",
      value: "destination_district_rate"
    }
  ];

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setOpenSuccess(value: boolean) {
    this.openSuccess = value;
  }

  @Mutation
  public setCommoditySurchargeList(data: CommoditySurchargeList) {
    this.commoditySurchargeList = data;
  }

  @Mutation
  public setDefaultCommoditySurchargeList() {
    this.commoditySurchargeList = new CommoditySurchargeList(
      new Pagination(1, 10),
      []
    );
  }

  @Mutation
  public setCommoditySurchargeData(data: CommoditySurcharge) {
    this.commoditySurcharge = data;
  }

  @Mutation
  public setLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setLoadingAdd(value: boolean) {
    this.isLoadingAdd = value;
  }

  @Mutation
  public setError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setErrorEdit(value: boolean) {
    this.isErrorEdit = value;
  }

  @Mutation
  public setOpenPopupError(value: boolean) {
    this.openPopupError = value;
  }

  @Mutation
  public setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  public setStatus(value: string) {
    this.status = value;
  }

  @Mutation
  public async nextPage() {
    if (this.page >= 1) {
      this.page = Number(this.page) + 1;
    }
  }

  @Mutation
  public async prevPage() {
    this.page = Number(this.page) - 1;
  }

  @Mutation
  public async setPage(page: number) {
    this.page = page;
  }

  @Mutation
  public async setFirstPage() {
    this.page = 1;
  }

  @Mutation
  private setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  private setType(value: string) {
    this.typeCost = value;
  }

  @Action
  public _onGetList(params: {
    page: number;
    limit: number;
    search: string;
    status: string;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(CommoditySurchargePresenter);
    presenter
      .getCommoditySurchargeList(
        params.search,
        params.status,
        params.page,
        params.limit
      )
      .then(res => {
        this.setCommoditySurchargeList(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public _onGetDetail(id: number) {
    this.setLoading(true);
    const presenter = container.resolve(CommoditySurchargePresenter);
    presenter
      .getDetailCommoditySurcharge(id)
      .then((res: CommoditySurcharge) => {
        this.setCommoditySurchargeData(res);
        this.setPeriodeStart(new Date(res.startedAt));
        this.setPeriodeEnd(
          res.expiredAt.split("T")[1] === "00:00:00+07:00"
            ? new Date(res.expiredAt)
            : new Date(new Date(res.expiredAt).setUTCHours(-1))
        );
        this.setPastStartDate(new Date(res.startedAt));
        this.setPastEndDate(new Date(res.expiredAt));
        this.setType(res.type);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public async getDetail(id: number): Promise<CommoditySurcharge> {
    const presenter = container.resolve(CommoditySurchargePresenter);
    return presenter.getDetailCommoditySurcharge(id);
  }

  @Action
  public async setNextPage() {
    await this.nextPage();
    this._onGetList({
      page: this.page,
      limit: 10,
      search: this.search,
      status: this.status
    });
  }

  @Action
  public async setPrevPage() {
    if (this.page !== 1) {
      await this.prevPage();
      this._onGetList({
        page: this.page,
        limit: 10,
        search: this.search,
        status: this.status
      });
    }
  }

  @Action
  public setPageAction(value: number) {
    this.setPage(value);
    this._onGetList({
      page: value,
      limit: 10,
      search: this.search,
      status: this.status
    });
  }

  // Origin State
  public originCityOptionsData: Array<any> = [];
  public isAllOriginCityActive = false;
  public isLoadingOriginCity = false;
  public valueOrigin: Array<any> = [];
  public optionsSearchOriginCity: Array<any> = [];

  // Handle Origin City on Embargo
  @Mutation
  public setLoadingOriginCity(value: boolean) {
    this.isLoadingOriginCity = value;
  }

  @Mutation
  public setDefaultOriginCity() {
    this.originCityOptionsData = [];
    this.valueOrigin = [];
  }

  @Action
  public getCityOriginList(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
  }) {
    this.setLoadingOriginCity(true);
    this.setDefaultOriginCity();
    const presenter = container.resolve(LocationPresenter);
    presenter
      .getCityList(
        new ApiRequestList({
          ...params
        })
      )
      .then(() => {
        this.setError(false);
      })
      .catch(() => {
        this.setError(true);
      })
      .finally(() => {
        this.setLoadingOriginCity(false);
      });
  }

  @Mutation
  public setOptionsOriginCityData(data: CityEntities) {
    for (const item of data.cityData) {
      this.originCityOptionsData.push({
        name: `${item.name} (${item.code})`,
        code: item.code,
        isActive: false
      });
    }
  }

  // Destination State
  public destinationCityOptionsData: Array<any> = [];
  public isAllDestinationCityActive = false;
  public isLoadingDestinationCity = false;
  public valueDestination: Array<any> = [];
  public optionsSearchDestinationCity: Array<any> = [];

  // Handle Destination City on Embargo
  @Mutation
  public setLoadingDestinationCity(value: boolean) {
    this.isLoadingDestinationCity = value;
  }

  @Action
  public getCityDestinationList(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
  }) {
    this.setLoadingDestinationCity(true);
    const presenter = container.resolve(LocationPresenter);
    presenter
      .getCityList(
        new ApiRequestList({
          ...params
        })
      )
      .then(() => {
        this.setError(false);
      })
      .catch(() => {
        this.setError(true);
      })
      .finally(() => {
        this.setLoadingDestinationCity(false);
      });
  }

  @Mutation
  public setOptionsDestinationCityData(data: CityEntities) {
    for (const item of data.cityData) {
      this.destinationCityOptionsData.push({
        name: `${item.name} (${item.code})`,
        code: item.code,
        isActive: false
      });
    }
  }

  // Date Picker
  public convertPeriodeStart = new Date();
  public convertPeriodeEnd = new Date();
  public pastStartDate = new Date();
  public pastEndDate = new Date();

  @Mutation
  public setPeriodeStart(date: any) {
    this.convertPeriodeStart = new Date(date?.setUTCHours(24));
  }
  @Mutation
  public setPeriodeEnd(date: any) {
    this.convertPeriodeEnd = new Date(date?.setUTCHours(24));
  }
  @Mutation
  public setPastStartDate(date: any) {
    this.pastStartDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }
  @Mutation
  public setPastEndDate(date: any) {
    this.pastEndDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }

  @Action
  public async formSurcharge(params: {
    method: any;
    payload: AddCommoditySurchargeApiRequest;
  }) {
    const presenter = container.resolve(CommoditySurchargePresenter);
    return presenter.formSurcharge(params);
  }

  @Action
  public _onEdit(params: {
    Id: number;
    type: string;
    amount: number;
    quantifier: string;
    startedAt: string;
    expiredAt: string;
  }) {
    this.setLoadingAdd(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(CommoditySurchargePresenter);
    presenter
      .editCommoditySurcharge(
        new EditCommoditySurchargeApiRequest(
          params.Id,
          params.type,
          params.amount,
          params.quantifier,
          params.startedAt,
          params.expiredAt
        )
      )
      .then(() => {
        this.setOpenModal(false);
        this.setOpenSuccess(true);
        this.setErrorEdit(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setOpenModal(false);
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            error,
            "Perubahan Commodity Surcharge Gagal!",
            () => this._onEdit(params)
          )
        );
      })
      .finally(() => {
        this.setLoadingAdd(false);
        MainAppController.closeLoading();
      });
  }

  // Check is exist
  isExist = true;

  @Mutation
  public setIsExist(value: boolean) {
    this.isExist = value;
  }

  isExistMessage = "";
  isSaveExist = false;
  @Mutation
  public setIsSaveExist(value: boolean) {
    this.isSaveExist = value;
  }
  @Mutation
  public setIsExistMessage(value: string) {
    this.isExistMessage = value;
  }
}

export const CommoditySurchargeController = getModule(CommoditySurchargeStore);

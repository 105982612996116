/* eslint-disable @typescript-eslint/camelcase */
import {
  ChangeCommoditySurchargeRequestInterface,
  EditCommoditySurchargeRequestInterface
} from "../contracts/CommoditySurchargeRequest";
import { TierCalculation } from "@/domain/entities/CommoditySurcharge";
import parseInputFloatToFloat from "@/app/infrastructures/misc/common-library/ParseInputFloatToFloat";
export class AddCommoditySurchargeApiRequest
  implements ChangeCommoditySurchargeRequestInterface {
  private id: number;
  private commodity_id: number;
  private type: string;
  private quantifier: string;
  private origin_city_code: string;
  private destination_city_code: string;
  private started_at: string;
  private expired_at: string;
  private tier_calculation: TierCalculation[]

  constructor(
    id: number,
    commodity_id: number,
    type: string,
    quantifier: string,
    origin_city_code: string,
    destination_city_code: string,
    started_at: string,
    expired_at: string,
    tier_calculation: TierCalculation[]
  ) {
    this.id = id;
    this.commodity_id = commodity_id;
    this.type = type;
    this.quantifier = quantifier;
    this.origin_city_code = origin_city_code;
    this.destination_city_code = destination_city_code;
    this.started_at = started_at;
    this.expired_at = expired_at;
    this.tier_calculation = tier_calculation;
  }
  public toJSON(): string {
    return JSON.stringify({
      id: this.id,
      commodity_id: this.commodity_id,
      type: this.type,
      quantifier: this.quantifier,
      origin_city_code: this.origin_city_code,
      destination_city_code: this.destination_city_code,
      started_at: this.started_at,
      expired_at: this.expired_at,
      tier_calculation: this.tier_calculation.map((item: TierCalculation) => {
        return {
          tier_start: parseInputFloatToFloat(item.tierStart),
          tier_end: parseInputFloatToFloat(item.tierEnd),
          tier_amount: this.type ===  "percentage" ? parseInputFloatToFloat(item.tierAmount) : Number(item.tierAmount.replace(".", "")),
        }
      })
    });
  }
}

export class EditCommoditySurchargeApiRequest
  implements EditCommoditySurchargeRequestInterface {
  private id: number;
  private type: string;
  private amount: number;
  private quantifier: string;
  private started_at: string;
  private expired_at: string;

  constructor(
    id: number,
    type: string,
    amount: number,
    quantifier: string,
    started_at: string,
    expired_at: string
  ) {
    this.id = id;
    this.type = type;
    this.amount = amount;
    this.quantifier = quantifier;
    this.started_at = started_at;
    this.expired_at = expired_at;
  }
  public toJSON(): string {
    return JSON.stringify({
      id: this.id,
      type: this.type,
      amount: this.amount,
      quantifier: this.quantifier,
      started_at: this.started_at,
      expired_at: this.expired_at
    });
  }
}


import { Options, Vue } from "vue-class-component";
import {
  dateToDateString,
  dateToTime,
  checkRolePermission,
  formatPriceIDR
} from "@/app/infrastructures/misc/Utils";
import { CommoditySurchargeController } from "@/app/ui/controllers/CommoditySurchargeController";
import { PRICING_COMMODITY } from "@/app/infrastructures/misc/RolePermission";
import TableV1 from "@/app/ui/components/table/index.vue";
import { TierCalculation } from "@/domain/entities/CommoditySurcharge";

@Options({
  components: {
    TableV1
  },
  computed: {
    isEditAble() {
      return checkRolePermission(PRICING_COMMODITY.EDIT);
    }
  }
})
export default class Detail extends Vue {
  goBack() {
    this.$router.push(`/tarif/surcharge`);
  }

  goEdit(id: number) {
    this.$router.push(`/tarif/surcharge/${id}/edit`);
  }

  // Loading
  get isLoading() {
    return CommoditySurchargeController.isLoading;
  }

  // Get Detail Data
  created() {
    this.getDetail();
  }

  get id() {
    return Number(this.$route.params.id);
  }

  getDetail() {
    CommoditySurchargeController._onGetDetail(this.id);
  }

  amountIDR = "";

  get dataDetail() {
    const detail = CommoditySurchargeController.commoditySurcharge;
    this.amountIDR = formatPriceIDR(detail.amount);
    return detail;
  }

  // Format Date
  formatDate(date: string) {
    return `${dateToDateString(new Date(date))} ${dateToTime(
      new Date(date)
    )} WIB`;
  }

  //dateString
  formatDateString(date: string) {
    return `${dateToDateString(new Date(date))}`;
  }

  // Handle Error
  get isError() {
    return CommoditySurchargeController.isError;
  }
  get errorCause() {
    return CommoditySurchargeController.errorCause;
  }

  get tipeBiaya() {
    return this.dataDetail.type
            .split("_")
            .map(string =>
              string
                ? string.charAt(0).toUpperCase() + string.slice(1)
                : ""
            )
            .join(" ")
  }

  statusTitle(status: string) {
    switch (status.toLowerCase()) {
      case "active":
        return "Aktif";
      case "inactive":
        return "Tidak Aktif";
      case "pending":
        return "Menunggu";
      default:
        return "-";
    }
  }

  styleStatus(status: string) {
    switch (status.toLowerCase()) {
      case "active":
        return "bg-green-lp-300 text-green-lp-200";
      case "inactive":
        return "bg-red-300 text-red-lp-200";
      case "pending":
        return "bg-yellow-lp-200 text-yellow-lp-100";
      default:
        return "bg-green-lp-300 text-green-lp-200";
    }
  }

  get statusEditable() {
    return this.dataDetail.status.toLowerCase() === "active" || this.dataDetail.status.toLowerCase() === "pending";
  }

  get dataTiers() {
    return this.dataDetail.tierCalculation;
  }

  columns = [
    {
      name: "Tier",
      key: "tier",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      render: (item: TierCalculation) => {
        return `<span class='flex text-black-lp-300'>${item.tier}</span>`;
      }
    },
    {
      name: "Dimulai dari",
      key: "start",
      styleHead: "w-4/12 text-left whitespace-no-wrap",
      render: (item: TierCalculation) => {
        return `<span class='flex text-black-lp-300'>${item.tierStart}</span>`;
      }
    },
    {
      name: "Sampai",
      key: "start",
      styleHead: "w-4/12 text-left whitespace-no-wrap",
      render: (item: TierCalculation) => {
        return `<span class='flex text-black-lp-300'>${item.tierEnd}</span>`;
      }
    },
    {
      name: "Biaya Tambahan",
      key: "start",
      styleHead: "w-4/12 text-left whitespace-no-wrap",
      render: (item: TierCalculation) => {
        return `<span class='flex text-black-lp-300'>${item.tierAmount}</span>`;
      }
    }
  ];
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "inline-flex" }
const _hoisted_2 = { class: "w-full md:w-8/12" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-row justify-between mt-4"
}
const _hoisted_4 = { class: "w-1/2 pr-3" }
const _hoisted_5 = { class: "flex flex-row justify-between mt-4" }
const _hoisted_6 = { class: "w-1/2 pr-3" }
const _hoisted_7 = { class: "flex flex-row justify-between mt-4" }
const _hoisted_8 = { class: "flex flex-row justify-between mt-4" }
const _hoisted_9 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_10 = { class: "text-red-lp-100 text-12px xxl:text-14px" }
const _hoisted_11 = {
  key: 1,
  class: "flex flex-col"
}
const _hoisted_12 = { class: "text-red-lp-100 text-12px xxl:text-14px" }
const _hoisted_13 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_14 = {
  key: 1,
  class: "flex flex-col"
}
const _hoisted_15 = { class: "flex flex-row justify-between mt-4" }
const _hoisted_16 = { class: "w-1/2 mr-4" }
const _hoisted_17 = { class: "w-1/2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_Title = _resolveComponent("Title")!
  const _component_LpInput = _resolveComponent("LpInput")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_SelectSearch = _resolveComponent("SelectSearch")!
  const _component_DatePickerV2 = _resolveComponent("DatePickerV2")!
  const _component_Select = _resolveComponent("Select")!
  const _component_MultipleSelectSearchV1 = _resolveComponent("MultipleSelectSearchV1")!
  const _component_multiple_select_search = _resolveComponent("multiple-select-search")!
  const _component_CalculationTiers = _resolveComponent("CalculationTiers")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!

  return (_openBlock(), _createBlock(_component_DetailLayout, {
    onBack: _ctx.goBack,
    title: _ctx.titlePage,
    "full-page": "",
    "custom-class": "px-0",
    "vertical-separator": false,
    "leave-page-confirmation": _ctx.leavePageConfirmation,
    "onUpdate:leave-page-confirmation": _cache[6] || (_cache[6] = ($event: any) => (_ctx.leavePageConfirmation = $event)),
    onLeavePage: _ctx.onLeavePage,
    loading: _ctx.apiGetData.loading,
    error: !!this.apiGetData.errorType,
    errorType: this.apiGetData.errorType,
    onTryAgain: _ctx.getDetail
  }, {
    "top-right": _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_lp_button, {
          "text-color": "white",
          customClass: "w-full py-2.5",
          title: "Simpan",
          onClick: _ctx.onCreate,
          disabled: _ctx.isDisabled
        }, null, 8, ["onClick", "disabled"])
      ])
    ]),
    data: _withCtx(() => [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_Title, { label: "Kriteria Biaya Tambahan Komoditas" }),
        (!_ctx.isFormTypeAdd)
          ? (_openBlock(), _createBlock("div", _hoisted_3, [
              _createVNode("div", _hoisted_4, [
                _createVNode(_component_DataWrapper, {
                  label: "Biaya Tambahan ID",
                  asterisk: "",
                  class: "w-full"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_LpInput, {
                      type: "text",
                      modelValue: _ctx.form.id,
                      disabled: ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true),
        _createVNode("div", _hoisted_5, [
          _createVNode("div", _hoisted_6, [
            _createVNode(_component_DataWrapper, {
              label: "Nama Komoditas",
              asterisk: "",
              class: "w-full"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SelectSearch, {
                  class: "my-2 w-full",
                  onFilter: _ctx.fetchCommodity,
                  onChange: _ctx.onCheckIsExist,
                  isLoading: _ctx.isLoadingCommodity,
                  options: _ctx.commodityList,
                  keyName: "name",
                  keyValue: "code",
                  placeholder: "Ketik atau pilih komoditas",
                  modelValue: _ctx.form.commodity,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.form.commodity = $event)),
                  isShowErrorMessage: false,
                  key: _ctx.form.commodity,
                  isDisabled: _ctx.form.status
                }, null, 8, ["onFilter", "onChange", "isLoading", "options", "modelValue", "isDisabled"])
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode("div", _hoisted_7, [
          _createVNode(_component_DataWrapper, {
            label: "Kota Asal",
            asterisk: "",
            class: "w-full mr-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SelectSearch, {
                class: "my-2 w-full",
                onFilter: _ctx.fetchOriginCity,
                isLoading: _ctx.isLoadingOrigin,
                options: _ctx.cityOriginList,
                keyName: "name",
                keyValue: "code",
                placeholder: "Ketik atau pilih Kota Asal",
                modelValue: _ctx.form.origin,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.form.origin = $event)),
                isShowErrorMessage: false,
                key: _ctx.form.origin,
                isDisabled: _ctx.form.status
              }, null, 8, ["onFilter", "isLoading", "options", "modelValue", "isDisabled"])
            ]),
            _: 1
          }),
          _createVNode(_component_DataWrapper, {
            label: "Kota Tujuan",
            asterisk: "",
            class: "w-full"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SelectSearch, {
                class: "my-2 w-full",
                onFilter: _ctx.fetchDestinationCity,
                isLoading: _ctx.isLoadingDestination,
                options: _ctx.cityDestinationList,
                keyName: "name",
                keyValue: "code",
                placeholder: "Ketik atau pilih Kota Tujuan",
                modelValue: _ctx.form.destination,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.form.destination = $event)),
                isShowErrorMessage: false,
                key: _ctx.form.destination,
                isDisabled: _ctx.form.status
              }, null, 8, ["onFilter", "isLoading", "options", "modelValue", "isDisabled"])
            ]),
            _: 1
          })
        ]),
        _createVNode("div", _hoisted_8, [
          _createVNode(_component_DataWrapper, {
            label: "Tanggal Mulai",
            asterisk: "",
            class: "w-full mr-4"
          }, {
            default: _withCtx(() => [
              (_ctx.isFormTypeAdd)
                ? (_openBlock(), _createBlock("div", _hoisted_9, [
                    _createVNode(_component_DatePickerV2, {
                      modelValue: _ctx.form.periodeStart,
                      onSelected: _ctx.setPeriodeStart,
                      minDate: new Date(new Date().setDate(new Date().getDate() + 1)),
                      class: `${(_ctx.invalidStartDateAdd) && 'date-error'} pt-3`,
                      disabled: _ctx.form.status
                    }, null, 8, ["modelValue", "onSelected", "minDate", "class", "disabled"]),
                    _withDirectives(_createVNode("span", _hoisted_10, "Tanggal mulai tidak boleh lebih dari atau sama dengan tanggal akhir", 512), [
                      [_vShow, _ctx.invalidStartDateAdd]
                    ])
                  ]))
                : (_openBlock(), _createBlock("div", _hoisted_11, [
                    _createVNode(_component_DatePickerV2, {
                      modelValue: _ctx.form.periodeStartEdit,
                      class: `${(_ctx.invalidStartDateEdit) && 'date-error'} pt-3`,
                      onSelected: _ctx.setPeriodeStart,
                      minDate: _ctx.form.pastStartDate,
                      disabled: _ctx.form.status
                    }, null, 8, ["modelValue", "class", "onSelected", "minDate", "disabled"]),
                    _withDirectives(_createVNode("span", _hoisted_12, "Tanggal mulai tidak boleh lebih dari atau sama dengan tanggal akhir", 512), [
                      [_vShow, _ctx.invalidStartDateEdit]
                    ])
                  ]))
            ]),
            _: 1
          }),
          _createVNode(_component_DataWrapper, {
            label: "Tanggal Berakhir",
            asterisk: "",
            class: "w-full"
          }, {
            default: _withCtx(() => [
              (_ctx.isFormTypeAdd)
                ? (_openBlock(), _createBlock("div", _hoisted_13, [
                    _createVNode(_component_DatePickerV2, {
                      modelValue: _ctx.form.periodeEnd,
                      onSelected: _ctx.setPeriodeEnd,
                      minDate: 
                  new Date(
                    new Date(_ctx.form.periodeStart).setDate(
                      new Date(_ctx.form.periodeStart).getDate() + 1
                    )
                  )
                ,
                      class: `${(_ctx.invalidStartDateAdd) && 'date-error'} pt-3`,
                      disabled: _ctx.invalidStartDateAdd
                    }, null, 8, ["modelValue", "onSelected", "minDate", "class", "disabled"])
                  ]))
                : (_openBlock(), _createBlock("div", _hoisted_14, [
                    _createVNode(_component_DatePickerV2, {
                      modelValue: _ctx.form.periodeEndEdit,
                      onSelected: _ctx.setPeriodeEnd,
                      minDate: 
                  new Date(
                    new Date(_ctx.form.periodeStartEdit).setDate(
                      new Date(_ctx.form.periodeStartEdit).getDate() + 1
                    )
                  )
                ,
                      class: "pt-3",
                      disabled: _ctx.invalidStartDateEdit
                    }, null, 8, ["modelValue", "onSelected", "minDate", "disabled"])
                  ]))
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_Title, {
          label: "Aturan Biaya Tambahan",
          class: "mt-5"
        }),
        _createVNode("div", _hoisted_15, [
          _createVNode("div", _hoisted_16, [
            _createVNode(_component_DataWrapper, {
              label: "Tipe Biaya",
              asterisk: "",
              class: "w-full"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Select, {
                  dataSelect: _ctx.typeData,
                  onOpenSelect: _ctx.onOpenTypeSelect,
                  onCloseSelect: _ctx.onCloseTypeSelect,
                  onChooseSelect: _ctx.onSelectType,
                  value: _ctx.form.type,
                  isOpenMenu: _ctx.typeSelect,
                  class: "text-12px xxl:text-14px my-2 z-10",
                  isDisabled: _ctx.form.status
                }, null, 8, ["dataSelect", "onOpenSelect", "onCloseSelect", "onChooseSelect", "value", "isOpenMenu", "isDisabled"])
              ]),
              _: 1
            })
          ]),
          _createVNode("div", _hoisted_17, [
            (_ctx.form.type === 'percentage')
              ? (_openBlock(), _createBlock(_component_DataWrapper, {
                  key: 0,
                  label: "Parameter Perhitungan",
                  asterisk: "",
                  class: "w-full"
                }, {
                  default: _withCtx(() => [
                    (_ctx.isFormTypeAdd)
                      ? (_openBlock(), _createBlock(_component_MultipleSelectSearchV1, {
                          key: 0,
                          dataSelect: _ctx.addObjectOptionsData,
                          onOpenSelect: _ctx.onOpenAddObjectSelect,
                          onCloseSelect: _ctx.onCloseAddObjectSelect,
                          onChooseSelect: _ctx.onSelectAddObject,
                          dataValue: _ctx.form.quantifier,
                          isOpenMenu: _ctx.addObjectSelect,
                          class: "w-full my-2",
                          onTyping: _ctx.onSearchAddObject,
                          isLoading: _ctx.isLoadingAddObject,
                          optionsBySearch: _ctx.optionsSearchAddObject,
                          placeholder: "Ketik atau pilih parameter perhitungan",
                          isDisabled: _ctx.form.status
                        }, null, 8, ["dataSelect", "onOpenSelect", "onCloseSelect", "onChooseSelect", "dataValue", "isOpenMenu", "onTyping", "isLoading", "optionsBySearch", "isDisabled"]))
                      : (_openBlock(), _createBlock(_component_multiple_select_search, {
                          key: 1,
                          options: _ctx.addObjectOptionsData,
                          modelValue: _ctx.form.quantifier,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.form.quantifier = $event)),
                          keyName: "name",
                          keyValue: "value",
                          placeholder: "Ketik atau pilih Objek Tambahan",
                          onFilter: _ctx.onSearchAddObject,
                          isDisabled: _ctx.form.status,
                          class: "my-2"
                        }, null, 8, ["options", "modelValue", "onFilter", "isDisabled"]))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _createVNode(_component_CalculationTiers, {
          isValid: _ctx.validCalculationTier,
          "onUpdate:isValid": _cache[5] || (_cache[5] = ($event: any) => (_ctx.validCalculationTier = $event)),
          tiers: _ctx.form.tiers,
          tipeBiaya: _ctx.form.type,
          tierDisable: _ctx.form.status,
          onAddTier: _ctx.addTier,
          onRemoveTier: _ctx.removeTier
        }, null, 8, ["isValid", "tiers", "tipeBiaya", "tierDisable", "onAddTier", "onRemoveTier"])
      ])
    ]),
    _: 1
  }, 8, ["onBack", "title", "leave-page-confirmation", "onLeavePage", "loading", "error", "errorType", "onTryAgain"]))
}
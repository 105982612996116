
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import Skeleton from "@/app/ui/components/skeleton/index.vue";

@Options({
  components: {
    Skeleton
  },
  props: {
    dataSelect: {
      default: [],
      type: Array
    },
    onOpenSelect: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onCloseSelect: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onChooseSelect: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onTyping: {
      default: Function as PropType<() => void>,
      type: Function
    },
    dataValue: {
      default: [],
      type: Array
    },
    optionsBySearch: {
      default: [],
      type: Array
    },
    isOpenMenu: {
      default: false,
      type: Boolean
    },
    isDisabled: {
      default: false,
      type: Boolean
    },
    isLoading: {
      default: false,
      type: Boolean
    },
    placeholder: {
      default: "",
      type: String
    }
  },
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class Select extends Vue {
  isAllActive = false;
  isValueActive(value: boolean) {
    this.isAllActive = value;
  }
  isAnyValue(data: any) {
    const temp: Array<any> = [];
    for (const item of data) {
      if (item.isActive) temp.push(item);
    }
    return temp.length !== 0;
  }
  valueSearch = "";
  onSearch(value: string) {
    this.valueSearch = value;
  }
}


import { Options, Vue } from "vue-class-component";
import Input from "@/app/ui/components/input/index.vue";
import Toggle from "@/app/ui/components/toggle/index.vue";
import {
  dateToTime,
  formatInputMoney,
  firstCapitalize,
  dateToDateString
} from "@/app/infrastructures/misc/Utils";
import { CommoditySurchargeController } from "@/app/ui/controllers/CommoditySurchargeController";

@Options({
  components: {
    Input,
    Toggle
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.onCloseSuccess();
      next();
    }
  }
})
export default class Edit extends Vue {
  goBack() {
    this.$router.push(`/tarif/surcharge/${this.id}`);
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  disable = true;

  // Loading
  get isLoading() {
    return CommoditySurchargeController.isLoading;
  }

  // get data
  created() {
    this.getDetailCommodity();
  }
  getDetailCommodity() {
    CommoditySurchargeController._onGetDetail(Number(this.$route.params.id));
  }

  createdAt: any = "";
  createdBy: any = "";
  updateAt: any = "";
  updateBy: any = "";

  get typeCost() {
    return CommoditySurchargeController.typeCost;
  }

  get dataDetail() {
    const detail = CommoditySurchargeController.commoditySurcharge;
    this.createdAt = this.formatDate(detail.createdAt);
    this.createdBy = detail.createdBy;
    this.updateAt =
      detail.updatedAt === "0001-01-01T00:00:00Z" || detail.updatedAt === null
        ? this.formatDate(detail.createdAt)
        : this.formatDate(detail.updatedAt);
    this.addObject = detail.quantifier;
    this.updateBy = detail.updatedBy;
    return detail;
  }

  // Modal Success
  get openSuccess() {
    return CommoditySurchargeController.openSuccess;
  }
  onCloseSuccess() {
    CommoditySurchargeController.setOpenSuccess(false);
  }

  // Save data
  onOpenSave() {
    return CommoditySurchargeController.setOpenModal(true);
  }
  get openSave() {
    return CommoditySurchargeController.openModal;
  }
  onCloseSave() {
    CommoditySurchargeController.setOpenModal(false);
  }

  // validation
  get isDisabledSave() {
    return (
      this.invalidStartDate ||
      (this.typeChargeValue === "percentage" &&
        this.additionalObject.length < 1)
    );
  }

  _onEdit() {
    this.onCloseSave();
    CommoditySurchargeController._onEdit({
      Id: Number(this.$route.params.id),
      type: this.typeChargeValue,
      amount: Number(
        `${this.dataDetail.amount}`.replace(/\./g, "").replace(/,/g, ".")
      ),
      quantifier:
        this.typeChargeValue === "percentage"
          ? this.additionalObject.map((i: any) => i.value).toString()
          : this.addObject,
      startedAt: new Date(this.periodeStart.setUTCHours(0)).toISOString(),
      expiredAt: new Date(this.periodeEnd.setUTCHours(0)).toISOString()
    });
  }

  // Type biaya
  typeChargeSelect = false;
  onOpenTypeChargeSelect() {
    this.typeChargeSelect = true;
  }
  onCloseTypeChargeSelect() {
    this.typeChargeSelect = false;
  }
  typeChargeName = `${this.typeCost}`;
  typeChargeValue = `${this.typeCost}`;
  onSelectTypeCharge(name: string, value: string) {
    this.onCloseTypeChargeSelect();
    this.typeChargeName = name;
    this.typeChargeValue = value;
  }

  typeData = [
    { name: "Percentage", value: `percentage` },
    { name: "Per Kg", value: "per_kg" },
    { name: "Flat", value: "flat" }
  ];

  // Type Pengiriman
  get addObjectOptionsData() {
    return CommoditySurchargeController.addObjectOptionsData;
  }

  valueAddObject: Array<any> = [];
  optionsSearchAddObject: Array<any> = [];
  addObjectSelect = false;

  onSearchAddObject(value: string) {
    const temp = this.addObjectOptionsData.filter(
      item =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.value.toLowerCase().includes(value.toLowerCase())
    );
    this.optionsSearchAddObject = temp;
  }
  additionalObject = this.parseQuantifier;
  get parseQuantifier() {
    if (this.dataDetail.quantifier.split(",").length > 0) {
      const newQuantifier = this.dataDetail.quantifier.split(",");

      return newQuantifier.map(e => ({
        name: firstCapitalize(e.split("_").join(" ")),
        value: e
      }));
    }
    return [];
  }

  // Date
  get pastStartDate(): any {
    return CommoditySurchargeController.pastStartDate;
  }
  get pastEndDate(): any {
    return CommoditySurchargeController.pastEndDate;
  }
  get periodeStart(): any {
    return CommoditySurchargeController.convertPeriodeStart;
  }
  setPeriodeStart(date: any) {
    CommoditySurchargeController.setPeriodeStart(date);
  }
  get periodeEnd(): any {
    return CommoditySurchargeController.convertPeriodeEnd;
  }
  setPeriodeEnd(date: any) {
    CommoditySurchargeController.setPeriodeEnd(date);
  }
  get invalidStartDate() {
    return new Date(
      this.periodeStart.getFullYear(),
      this.periodeStart.getMonth(),
      this.periodeStart.getDate()
    ).getTime() >=
      new Date(
        this.periodeEnd.getFullYear(),
        this.periodeEnd.getMonth(),
        this.periodeEnd.getDate()
      ).getTime()
      ? true
      : false;
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  // Object Format
  addObject = "";
  filterInputAddObject(e: any) {
    if (/^0/.test(e.target.value)) {
      e.target.value = e.target.value.replace(/^0/, "");
    } else {
      e.target.value = e.target.value.replace(/\D+/g, "");
      e.target.value = formatInputMoney(e.target.value);
    }
    this.addObject = e.target.value;
  }

  // Format Date
  formatDate(date: any) {
    return `${dateToDateString(new Date(date))}, ${dateToTime(
      new Date(date)
    )} WIB`;
  }
}


import { Options, Vue } from "vue-class-component";
import TableV1 from "@/app/ui/components/table/index.vue";
import router from "@/app/ui/router";
import firstCapitalize from "@/app/infrastructures/misc/common-library/FirstCapitalize";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";
import { CommoditySurchargeController } from "@/app/ui/controllers/CommoditySurchargeController";
import debounce from "lodash/debounce";
import { PRICING_COMMODITY_SURCHARGE } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    TableV1
  },
  computed: {
    getCurrentRoute() {
      return this.$route.meta.title;
    },
    isCreateAble() {
      return (
        checkRolePermission(PRICING_COMMODITY_SURCHARGE.CREATE) && !this.isError
      );
    },
    isDetailAble() {
      return checkRolePermission(PRICING_COMMODITY_SURCHARGE.DETAIL);
    }
  }
})
export default class RouteManagement extends Vue {
  // Breadcrumb
  breadcrumb: Array<string> = [];

  // Search
  onSearch(value: string) {
    this.setDefaultPage();
    CommoditySurchargeController.setSearch(value);
    this.getListData(1, value, this.status);
  }
  public clearSearch() {
    this.setDefaultPage();
    CommoditySurchargeController.setLoading(true);
    CommoditySurchargeController.setSearch("");
    this.getListData(1, "", this.status);
  }
  get search() {
    return CommoditySurchargeController.search;
  }

  // Filter
  get status() {
    return CommoditySurchargeController.status;
  }

  setDefaultPage() {
    CommoditySurchargeController.setPage(1);
  }

  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Menunggu", value: "pending" },
    { name: "Aktif", value: "active" },
    { name: "Tidak Aktif", value: "inactive" }
  ];
  statusSelect = false;
  statusName = "";

  onOpenStatusSelect() {
    this.setDefaultPage();
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name: string, value: string) {
    this.onCloseStatusSelect();
    this.statusName = name;
    CommoditySurchargeController.setStatus(value);
    this.getListData(1, this.search, value);
  }

  // Add New Commodity Surcharge
  toPageAdd() {
    router.push("/tarif/surcharge/add");
  }

  // Loading
  get isLoading() {
    return CommoditySurchargeController.isLoading;
  }

  // Pagination
  get page() {
    return CommoditySurchargeController.page;
  }
  get limit() {
    return CommoditySurchargeController.limit;
  }
  nextPage() {
    CommoditySurchargeController.setNextPage();
  }

  prevPage() {
    CommoditySurchargeController.setPrevPage();
  }

  toPage = debounce(value => {
    CommoditySurchargeController.setPageAction(value);
  }, 1000);

  // Get Data
  created() {
    this.getListData(1, "", "");
    CommoditySurchargeController.setSearch("");
    CommoditySurchargeController.setStatus("");
    this.setFirstPage();
  }

  onReload() {
    CommoditySurchargeController._onGetList({
      page: 1,
      limit: 10,
      search: "",
      status: ""
    });
  }
  getListData(page: number, search: string, status: string) {
    CommoditySurchargeController._onGetList({
      page,
      limit: 10,
      search,
      status
    });
  }
  setFirstPage() {
    CommoditySurchargeController.setFirstPage();
  }
  get listData() {
    return CommoditySurchargeController.commoditySurchargeList.data;
  }

  // Data Table
  columns = [
    {
      name: "Surcharge ID",
      key: "id",
      styleHead: "w-32 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<span class='text-center text-red-lp-200'>${item.id}</span>`;
      }
    },
    {
      name: "Nama Komoditas",
      key: "legName",
      styleHead: "w-50 text-left",
      styleBody: "",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.commodityName}</div>`;
      }
    },
    {
      name: "Kota Asal",
      key: "legName",
      styleHead: "w-36 text-left",
      styleBody: "",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.originCityName}</div>`;
      }
    },
    {
      name: "Kota Tujuan",
      key: "legName",
      styleHead: "w-36 text-left",
      styleBody: "",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.destinationCityName}</div>`;
      }
    },
    {
      name: "Periode Surcharge",
      key: "legName",
      styleHead: "w-50 text-left",
      styleBody: "",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${dateToDateString(item.startedAt)} - <br/> ${dateToDateString(item.expiredAt)}</div>`;
      }
    },
    {
      name: "Tipe Biaya",
      key: "legName",
      styleHead: "w-32 text-left",
      styleBody: "",
      render: (item: any) => {
        return `<div class='flex'><div class='text-black-lp-300'>${
          item.type === "per_kg" ? "Per Kg" : firstCapitalize(item.type)
        }</div></div>`;
      }
    },
    {
      name: "Parameter Perhitungan",
      key: "legName",
      styleHead: "w-50 text-left",
      styleBody: "",
      render: (item: any) => {
        const quantifierValues =
          item.quantifier
            .split("_")
            .map((qtf: any) => firstCapitalize(qtf))
            .join(" ")
            .split(",").length > 1
            ? `<div class='flex text-center align-middle ml-1 rounded px-2 py-0 bg-gray-lp-400 text-black-lp-300'>
          <span class='self-center'>${item.quantifier.split(",").length -
            1}+</span>
          </div>`
            : "";
        return `<div class='flex'><div class='flex text-center align-middle rounded px-2 py-0 bg-gray-lp-400 text-black-lp-300'>
            <span class='self-center'>${
              item.quantifier
                .split("_")
                .map((qtf: any) => firstCapitalize(qtf))
                .join(" ")
                .split(",")[0]
            }</span>
          </div>
          ${quantifierValues}</div>`;
      }
    },
    {
      name: "Status",
      key: "status",
      styleHead: "w-32 text-left",
      styleBody: "",
      render: (item: any) => {
        return `
        <div class="flex">
      <div
        class="rounded-full px-2 py-0 ${this.styleStatus(
          item.status.toLowerCase()
        )}"
      ><span class="capitalize font-medium">${this.statusTitle(
        item.status.toLowerCase()
      )}</span></div></div>`;
      }
    }
  ];

  // To Detail
  onClickRow(item: any) {
    router.push(`/tarif/surcharge/${item.id}`);
  }

  statusTitle(status: string) {
    switch (status) {
      case "active":
        return "Aktif";
      case "inactive":
        return "Tidak Aktif";
      case "pending":
        return "Menunggu";
      default:
        return "-";
    }
  }

  styleStatus(status: string) {
    switch (status) {
      case "active":
        return "bg-green-lp-300 text-green-lp-200";
      case "inactive":
        return "bg-red-300 text-red-lp-200";
      case "pending":
        return "bg-yellow-lp-200 text-yellow-lp-100";
      default:
        return "bg-green-lp-300 text-green-lp-200";
    }
  }

  // Handle Error
  get isError() {
    return CommoditySurchargeController.isError;
  }
  get errorCause() {
    return CommoditySurchargeController.errorCause;
  }
}


import { Vue, Options, prop } from "vue-class-component";
import { TierCalculation } from "@/domain/entities/CommoditySurcharge";
import formatInputMoney from "@/app/infrastructures/misc/common-library/FormatInputMoney";
import parseInputFloatToFloat from "@/app/infrastructures/misc/common-library/ParseInputFloatToFloat";

class Props {
  tiers = prop<Array<TierCalculation>>({
    type: Array,
    default: [],
  });
  tipeBiaya = prop<string>({
    type: String,
    default: "percentage",
  });
  tierDisable = prop<boolean>({
    type: Boolean,
    default: false,
  })
}

@Options({
    emits: ["addTier", "removeTier", "update:isValid"]
})
export default class CalculationTiers extends Vue.with(Props) {
    formatPrice(value: string) {
        return formatInputMoney(value.replace(/[\D]+/g, ""));
    }

    formatDecimal(value: string) {
        return value.replace(/[^0-9,]/g, '');
    }

    addTier() {
        if (!this.tierDisable) this.$emit("addTier")
    }

    removeTier(index: number) {
        if (!this.tierDisable) this.$emit("removeTier", index);
    }

    parseToNumber(str: string) {
        return parseInputFloatToFloat(str);
    }

    isErrorTierStartEnd(index: number) {
        const tierStart = this.convertTier(index).tierStart;
        const tierEnd = this.convertTier(index).tierEnd;

        this.tiers[index].tierStartMaxLength = this.digitAftercomma(this.tiers[index].tierStart);
        this.tiers[index].tierEndMaxLength = this.digitAftercomma(this.tiers[index].tierEnd);

        if (tierStart >= tierEnd) {
            this.tiers[index].tierStartError = "Tier awal tidak boleh lebih atau sama dengan tier akhir";
            this.tiers[index].tierEndError = "Tier akhir tidak boleh kurang atau sama dengan tier awal";
            return true
        } else {
            this.tiers[index].tierStartError = "";
            this.tiers[index].tierEndError = "";
            return false;
        }
    }

    
    isErrorTierAmount(index: number) {
        this.tiers[index].tierAmountMaxLength = this.digitAftercomma(this.tiers[index].tierAmount);
    }

    convertTier(index: number) {
        const tierStart = this.parseToNumber(
            this.tiers[index].tierStart
        );
        const tierEnd = this.parseToNumber(
            this.tiers[index].tierEnd
        );

        return {
            tierStart, tierEnd
        }
    }
    
    isErrorStartIntersection(index: number) {
        const tierStart = this.convertTier(index).tierStart;
        
        if (!tierStart) {
            this.tiers[index].tierStartError = "";
            return false;
        }
        if (index > 0) {
            const tierEnd = this.convertTier(index - 1).tierEnd;
            return tierStart <= tierEnd;
        }
        return this.isIntersect(tierStart, index);
    }

    isErrorEndIntersection(index: number) {
        const tierEnd = this.convertTier(index).tierEnd;
        if (!tierEnd) {
            this.tiers[index].tierEndError = "";
            return false;
        }
        return this.isIntersect(tierEnd, index);
    }

    isIntersect(val: any, index: number) {
        const result = false;
        for (let i = 0; i < this.tiers.length; i++) {
            if (index !== i) {
                const tempStart = this.convertTier(i).tierStart
                const tempEnd = this.convertTier(i).tierEnd;

                if (tempStart <= val && val <= tempEnd) {
                    return true;
                } 
            }
        }

        return result;
    }

    get isCalculationTierValid() {
        let result = true;
        for (let i = 0; i < this.tiers.length; i++) {
            const tier = this.tiers[i];
            if (!tier.tierStart || !tier.tierEnd || !tier.tierAmount) {
                result = false;
                break;
            }
            if (
                this.isErrorStartIntersection(i) ||
                this.isErrorEndIntersection(i) ||
                this.isErrorTierStartEnd(i)
            ) {
                result = false;
                break;
            }
        }
        this.$emit("update:isValid", result);
        return result;
    }

    digitAftercomma(val: string) {
        const valArr = val.split(",");
        if (valArr[1]) {
            return valArr[1].length > 1 ? String(val.length) : "";
        }
        
        return "";
    }
}


import { Options, Vue } from "vue-class-component";
import Input from "@/app/ui/components/input/index.vue";
import { CommodityController } from "@/app/ui/controllers/CommodityController";
import { CommoditySurchargeController } from "@/app/ui/controllers/CommoditySurchargeController";
import {
  convertArrayToString,
  dateToDateString
} from "@/app/infrastructures/misc/Utils";
import MultipleSelectSearchV1 from "@/app/ui/components/multiple-select-search/index.vue";
import { LocationController } from "@/app/ui/controllers/LocationController";
import debounce from "lodash/debounce";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import CalculationTiers from "./components/calculation-tiers.vue";
import { TierCalculation, CommoditySurcharge, FormSurcharge } from "@/domain/entities/CommoditySurcharge";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import firstCapitalize from "@/app/infrastructures/misc/common-library/FirstCapitalize";
import { ApiStateData } from "@/domain/entities/Api";
import { AddCommoditySurchargeApiRequest } from "@/data/payload/api/CommoditySurchargeApiRequest";

@Options({
  components: {
    Input,
    MultipleSelectSearchV1,
    CalculationTiers
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.answerLeavingPage) {
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  }
})
export default class Add extends Vue {
  get titlePage() {
    return this.$route.meta.title;
  }

  mounted() {
    this.setPeriodeStart(new Date(new Date().setDate(new Date().getDate() + 1))); 
    this.setPeriodeEnd(new Date(new Date(
        new Date(new Date().setDate(new Date().getDate() + 1))
      ).setDate(new Date(
        new Date(new Date().setDate(new Date().getDate() + 1))
      ).getDate() + 3)))
  }

  leavePageConfirmation = false;
  saveConfirmation = false;
  nextPath = "";
  answerLeavingPage = false;
  goBack() {
    this.$router.push(`/tarif/surcharge${!this.isFormTypeAdd ? `/${this.id}` : ''}`);
  }
  onLeavePage() {
    this.answerLeavingPage = true;
    this.$router.push(this.nextPath);
  }
  onCloseSuccess() {
    MainAppController.closeMessageModal();
    this.answerLeavingPage = true;
    this.goBack();
  }

  async onCreate() {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();

    const commodity: any = this.form.commodity;
    const city: any = this.form.origin;
    const destination: any = this.form.destination;

    const titleSuccess = this.isFormTypeAdd ? "Berhasil Dibuat" : "Berhasil Diperbarui";
    const msgSuccess = this.isFormTypeAdd ? "Tarif komoditas baru telah dibuat" : "Perubahan tarif komoditas ini telah tersimpan";
    const titleFailed = this.isFormTypeAdd ? "Gagal Dibuat" : "Gagal Diperbarui";

    try {
      await CommoditySurchargeController.formSurcharge({
        method: this.isFormTypeAdd ? "post" : "put",
        payload: new AddCommoditySurchargeApiRequest(
          this.form.id,
          commodity.code === "all" ? 0 : Number(commodity.code),
          this.form.type,
          this.form.type === "percentage"
            ? this.convertToString(this.form.quantifier, "value")
            : "",
          String(city.code).toLowerCase() === "all" ? "" : city.code,
          String(destination.code).toLowerCase() === "all" ? "" : destination.code,
          new Date(this.isFormTypeAdd ? this.form.periodeStart.setUTCHours(0) : this.form.periodeStartEdit.setUTCHours(0)).toISOString(),
          new Date(this.isFormTypeAdd ? this.form.periodeEnd.setUTCHours(0) : this.form.periodeEndEdit.setUTCHours(0)).toISOString(),
          this.form.tiers
        )
      });

      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: `“${commodity.name}” ${titleSuccess}`,
          message: msgSuccess,
          textSuccess: "OK",
          onSubmit: () => this.onCloseSuccess(),
          image: "badge-success",
          imageWidth: "36",
        })
      );
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, `“${commodity.name}” ${titleFailed}`, () =>
          this.onCreate()
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  get isDisabled() {
    const validateDate = this.dataDetail.id ? this.invalidStartDateEdit : this.invalidStartDateAdd;
    return (
      !this.form.commodity ||
      !this.form.type ||
      (this.form.type === "percentage" &&
        !this.convertToString(this.form.quantifier, "value")) ||
      !this.form.origin ||
      !this.form.destination ||
      validateDate ||
      !this.validCalculationTier
    );
  }

  created() {
    this.getData();
    this.getDetail();
    CommoditySurchargeController.setIsExist(false);
    CommoditySurchargeController.setDefaultCommoditySurchargeList();
  }

  get isFormTypeAdd() {
    return this.$route.meta.formType === "add";
  }

  get id() {
    return Number(this.$route.params.id);
  }

  form: FormSurcharge= new FormSurcharge()

  apiGetData = new ApiStateData();
  async getDetail() {
    if (!this.isFormTypeAdd) {
      this.apiGetData.loading = true;
      this.apiGetData.errorType = "";
      try {
        const detail: CommoditySurcharge = await CommoditySurchargeController.getDetail(this.id);
        if (detail.status.toLowerCase() === "inactive") {
          this.onCloseSuccess();
        } else {
          this.form.id = detail.id;
          this.form.commodity = {
            code: detail.commodityId,
            name: detail.commodityName === "all" ? "All Commodity" : detail.commodityName
          }

          this.form.origin = {
            code: detail.originCityCode,
            name: detail.originCityName
          }

          this.form.destination = {
            code: detail.destinationCityCode,
            name: detail.destinationCityName
          }

          this.form.periodeStartEdit = new Date(new Date(detail.startedAt).setUTCHours(24)) 
          this.form.pastStartDate = new Date(
                new Date(detail.startedAt).getFullYear(),
                new Date(detail.startedAt).getMonth(),
                new Date(detail.startedAt).getDate()
            ),
          this.form.periodeEndEdit = new Date((detail.expiredAt.split("T")[1] === "00:00:00+07:00"
                ? new Date(detail.expiredAt)
                : new Date(new Date(detail.expiredAt).setUTCHours(-1))).setUTCHours(24)),
          this.form.pastEndDate = new Date(detail.startedAt)
          this.form.type = detail.type
          this.form.quantifier = detail.quantifier === "null" ? [] : this.parseQuantifier(detail.quantifier)
          this.form.quantifierDefault = detail.quantifierDefault
          this.form.tiers = detail.tierCalculation.map((item: TierCalculation) => {
            return {
              tier: item.tier,
              tierStart: String(item.tierStart).replace(".", ","),
              tierStartError: "",
              tierStartMaxLength: item.tierStartMaxLength,
              tierEnd: String(item.tierEnd).replace(".", ","),
              tierEndError: "",
              tierEndMaxLength: item.tierEndMaxLength,
              tierAmount: detail.type === "percentage" ? String(item.tierAmount).replace(".", ",") : String(item.tierAmount),
              tierAmountError: "",
              tierAmountMaxLength: item.tierAmountMaxLength
            }
          })
          this.form.status = detail.status.toLowerCase() === "active"
        }
      } catch (err) {
        this.apiGetData.errorType = parsingErrorResponse(err).type;
      } finally {
        this.apiGetData.loading = false;
      }
    }
  }

  get dataDetail() {
    return CommoditySurchargeController.commoditySurcharge;
  }

  parseQuantifier(quantifier: string) {
    if (quantifier.split(",").length > 0) {
      const newQuantifier = quantifier.split(",");

      return newQuantifier.map(e => ({
        name: firstCapitalize(e.split("_").join(" ")),
        value: e
      }));
    }
    return [];
  }

  getData() {
    CommodityController.getCommodityList(new RequestListCommodity());
    LocationController.getCityOriginList({
      search: "",
      status: "",
      page: 1,
      limit: 10
    });
    LocationController.getCityDestinationList({
      search: "",
      status: "",
      page: 1,
      limit: 10
    });
    CommoditySurchargeController.setError(false);
  }

  setPeriodeEnd(date: Date) {
    this.setIsExist(false)
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    ).setUTCHours(24);

    this.form.periodeEnd = new Date(newDate)
    this.form.periodeEndEdit = new Date(newDate)
  }
  setPeriodeStart(date: Date) {
    this.setIsExist(false)
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    ).setUTCHours(24);

    this.form.periodeStart = new Date(newDate);
    this.form.periodeStartEdit = new Date(newDate);
  }
  get invalidStartDateAdd() {
    const start = new Date(
      this.form.periodeStart.getFullYear(),
      this.form.periodeStart.getMonth(),
      this.form.periodeStart.getDate()
    ).getTime();
    const end = new Date(
      this.form.periodeEnd.getFullYear(),
      this.form.periodeEnd.getMonth(),
      this.form.periodeEnd.getDate()
    ).getTime();
    return start >= end;
  }

  get invalidStartDateEdit() {
    const start = new Date(
      this.form.periodeStartEdit.getFullYear(),
      this.form.periodeStartEdit.getMonth(),
      this.form.periodeStartEdit.getDate()
    ).getTime();
    const end = new Date(
      this.form.periodeEndEdit.getFullYear(),
      this.form.periodeEndEdit.getMonth(),
      this.form.periodeEndEdit.getDate()
    ).getTime();
    return start >= end;
  }

  typeName = "";
  typeValue = "";
  typeData = [
    { name: "Pilih tipe biaya", value: "" },
    { name: "Per Kg", value: "per_kg" },
    { name: "Flat", value: "flat" },
    { name: "Percentage", value: "percentage" }
  ];
  typeSelect = false;
  onOpenTypeSelect() {
    this.typeSelect = true;
  }
  onCloseTypeSelect() {
    this.typeSelect = false;
  }
  onSelectType(name: string, value: string) {
    this.typeName = name;
    this.form.type = value;
    this.onCloseTypeSelect();
    this.form.tiers = this.form.tiers.map((item: TierCalculation) => {
      return {
        ...item,
        tierAmount: ""
      }
    });
    this.resetParameterPerhitungan();
  }

  resetParameterPerhitungan() {
    if (!this.isFormTypeAdd) {
      this.form.quantifier = this.form.quantifierDefault === "null" ? [] : this.parseQuantifier(this.form.quantifierDefault);
    } else {
      this.form.quantifier = []
    }
    
    let index = 0;
    for (const e of this.addObjectOptionsData) {
      this.addObjectOptionsData[index].isActive = false;
      index++;
    }
  }

  commodityValueSearch = "";
  get commodityList() {
    const data = CommodityController.commodityListOptions;
    if (this.commodityValueSearch) return data;
    else return [{ code: "all", name: "All Commodity" }, ...data];
  }
  get commoditySurchargeList() {
    return CommoditySurchargeController.commoditySurchargeList;
  }
  fetchCommodity = debounce((search: string) => {
    this.commodityValueSearch = search;
    CommodityController.getCommodityList(
      new RequestListCommodity({
        search: search
      })
    );
  }, 250);

  get isLoadingCommodity() {
    return CommodityController.isLoading;
  }

  cityOriginValueSearch = "";
  get cityOriginList() {
    const data = LocationController.originCityOptionsData;
    if (this.cityOriginValueSearch) return data;
    else return [{ code: "all", name: "All" }, ...data];
  }
  fetchOriginCity = debounce((search: string) => {
    this.cityOriginValueSearch = search;
    if (search.length >= 3 || !search)
      LocationController.getCityOriginList({
        search: search,
        status: "",
        page: 1,
        limit: 100
      });
  }, 250);
  get isLoadingOrigin() {
    return LocationController.isLoadingOriginCity;
  }

  cityDestinationValueSearch = "";
  get cityDestinationList() {
    const data = LocationController.destinationCityOptionsData;
    if (this.cityDestinationValueSearch) return data;
    else return [{ code: "all", name: "All" }, ...data];
  }
  fetchDestinationCity = debounce((search: string) => {
    this.cityDestinationValueSearch = search;
    if (search.length >= 3 || !search)
      LocationController.getCityDestinationList({
        search: search,
        status: "",
        page: 1,
        limit: 100
      });
  }, 250);
  get isLoadingDestination() {
    return LocationController.isLoadingDestinationCity;
  }

  addObjectOptionsData: Array<any> = [
    { name: "Publish Rate", value: "publish_rate", isActive: false },
    {
      name: "Shipping Surcharge",
      value: "shipping_surcharge",
      isActive: false
    },
    {
      name: "Origin District Rate",
      value: "origin_district_rate",
      isActive: false
    },
    {
      name: "Destination District Rate",
      value: "destination_district_rate",
      isActive: false
    }
  ];
  isAllAddObjectActive = false;
  isLoadingAddObject = false;
  optionsSearchAddObject: Array<any> = [];
  addObjectSelect = false;

  onSearchAddObject(value: string) {
    this.optionsSearchAddObject = this.addObjectOptionsData.filter(
      item =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.value.toLowerCase().includes(value.toLowerCase())
    );
  }
  onOpenAddObjectSelect() {
    this.addObjectSelect = true;
  }
  onCloseAddObjectSelect() {
    this.addObjectSelect = false;
  }
  handleAllValueAddObject(temp: any[]): any[] {
    this.form.quantifier = [];
    if (!this.isAllAddObjectActive) {
      for (const item of this.addObjectOptionsData) {
        this.isAllAddObjectActive = true;
        temp.push({ ...item, isActive: true });

        // Add value active to list
        this.form.quantifier.push({ ...item, isActive: true });
      }
    } else {
      for (const item of this.addObjectOptionsData) {
        this.isAllAddObjectActive = false;
        temp.push({ ...item, isActive: false });

        // Add value active to list
        this.form.quantifier = [];
      }
    }
    return temp;
  }
  handleItemAddObjectOptionsData(
    temp: any[],
    item: any,
    name: string,
    value: string
  ): any[] {
    temp.push({ ...item, isActive: !item.isActive });
    // Set Value Active
    for (const optionSearch of this.optionsSearchAddObject) {
      if (optionSearch.value === value) {
        optionSearch.isActive = !item.isActive;
      }
    }

    // Check is Exist
    let isExist = false;
    for (const valueAdd of this.form.quantifier) {
      if (valueAdd.value.includes(value)) {
        isExist = true;
        break;
      }
    }

    if (!isExist) this.form.quantifier.push({ ...item, isActive: true });
    else {
      // Get index
      const index = this.form.quantifier.findIndex((e: any) => e.value === value);
      if (index > -1) this.form.quantifier.splice(index, 1);
    }
    return temp;
  }
  handleNonAllValueAddObject(temp: any[], name: string, value: string): any[] {
    for (const item of this.addObjectOptionsData) {
      if (item.name === name || item.value === value) {
        temp = this.handleItemAddObjectOptionsData(temp, item, name, value);
      } else if (item.value === "all") {
        this.isAllAddObjectActive = false;
        temp.push({ ...item, isActive: false });
        const index = this.form.quantifier.findIndex((e: any) => e.value === "all");
        if (index > -1) this.form.quantifier.splice(index, 1);
      } else temp.push(item);
    }
    return temp;
  }
  onSelectAddObject(name: string, value: string) {
    let temp: any[] = [];
    if (value === "all") {
      temp = this.handleAllValueAddObject(temp);
    } else {
      temp = this.handleNonAllValueAddObject(temp, name, value);
    }
    this.addObjectOptionsData = temp;
  }

  // Convert Multiple Select to string
  convertToString(data: Array<any>, key: string) {
    return convertArrayToString(data, key);
  }

  onCheckIsExist(value: any) {
    CommoditySurchargeController._onGetList({
      page: 1,
      limit: 10,
      search: value.name,
      status: ""
    });
  }
  get list() {
    return CommoditySurchargeController.commoditySurchargeList.data;
  }
  setIsExist(value: boolean) {
    CommoditySurchargeController.setIsExist(value);
  }
  
  addTier() {
    this.form.tiers.push(new TierCalculation())
  }

  removeTier(index: number) {
    this.form.tiers.splice(index, 1);
  }

  validCalculationTier = false;
}
